<template>
      <div class="clear" id="form_nave_container">
            <b-form-input v-on:keyup.enter="searchField()" v-model="search" trim id="form_nave" type="text" placeholder="Faça sua busca aqui!" />
            <inline-svg @click="searchField()" id="search_icon" :class="(search != undefined) ? 'clickable' : ''" :src="require(`@/assets/svg/icon_search.svg`)" alt="search"></inline-svg>
            <b-button variant="link" v-if="data.search != undefined" id="clear" @click="clear()" title="Limpar"><b-icon icon="x-square" class="icon"></b-icon> </b-button>
      </div>  

      <!--
      <div class="search">
            <b-form-group class="mr-1" label-for="search" description="Procurar por..." >
                <b-form-input type="text" v-model="search" trim :state="SearchOK" />
            </b-form-group>
        <div class="actions">
              <b-button
                id="search" :disabled="StatelAll" @click="searchField()"
                title="Procurar"><b-icon icon="search" class="icon"></b-icon>Procurar
              </b-button>

        </div>
      </div>
      -->
</template>

<script>
var mixin = {
    data() {
        return {
            query: undefined,
            data: {
                search: undefined,
            },
        };
    },
};

import LengthValidator from "./lengthValidator"

export default {
  mixin,
    name: "SearchCasesModels",
    props: {
      data: Object,
    },
    data() {
        return {
            search: undefined,
        }
    },
    methods: {
    searchField() {
      if(this.SearchOK){
        var x = { search: this.search, }
        this.$emit('searchData', x)
      }
    },
    clear() {
      this.search = undefined;
      this.searchField();
    },
    },
  computed:{
      SearchOK(){
          return LengthValidator.Max(this.search, 3)
      }
  },
  watch: {
    data: {
      immediate: true,
      handler(x) {
        this.search = x.search;
      },
    },
  }
};
</script>


<style>

#form_nave_container {
    display: flex;
    padding: 0 5px;
}


button#clear {
    position: relative;
    left: -35px;
}
</style>