<template>
    <div class="phonegrid">
        <div class="phone" v-for="phone, index in phones" :key="`p${index}`">
          <a class="link" :href="(phone.linktype == 1) 
                    ? `tel:${removeNonNumbers(phone.number)}` 
                    : `${whatsappLink}${removeNonNumbers(phone.number)}`"
                    :target="(phone.linktype == 1) ? '_self' : '_blank'">
          <inline-svg v-if="verifyAsset(phone.icon)" class="icon" :src="require(`@/assets/svg/${phone.icon}.svg`)"></inline-svg>
          {{ phone.number }} </a>
        </div>
    </div>
</template>


<script>
import LengthValidator from "@/components/helpers/lengthValidator"
import EmailValidator from "@/components/helpers/emailValidator"
import verifyAsset from '@/components/helpers/verifyAsset';

export default {
  name: "ContactPhone",
  mixins: [verifyAsset],
  props: {
    dataUser: Object,
  },
  data() {
    return {
      name: undefined,
      email: undefined,
      message: undefined,
      phones: [],
    };
  },
  methods:{
    verifyDataUser(){
      if(this.dataUser != undefined){
        this.phones = this.dataUser.meliphones
        this.whatsappLink = this.dataUser.options.whatsapp_options.url
      }
    },
    removeNonNumbers(value){
      return value.replace(/\D/g, '')
    }
  },
  computed: {
    StateName() {
      return LengthValidator.Max(this.name, 3)
    },
    StateEmail() {
      return EmailValidator(this.email)
    },
    StateMessage() {
      return LengthValidator.Max(this.message, 10)
    },
    StatelAll(){
        return !(this.StateName && this.StateEmail && this.StateMessage)
    }
  },
  watch: {
    dataUser: {
      immediate: true,
      handler(){
        this.verifyDataUser()
      }
    }
  }
};
</script>
