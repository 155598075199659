<template>
    
        <div class="pagination">
            <div v-if="totalPages > 1">
                <b-form-group label-for="pag" description="Páginas" >
                    <b-pagination-nav
                        id="pag"
                        hide-ellipsis
                        :link-gen="MountPages"
                        v-model="localPage"
                        :number-of-pages="totalPages"
                        align="center"
                        limit="7"
                    ></b-pagination-nav>
                </b-form-group>
            </div>
        </div>
</template>

<script>

var mixin = {
    data() {
        return {
            currentPage: 1,
            totalPages: 1,
        };
    },
};

export default {
    mixin,
    props: {
        totalPages: Number,
        currentPage: Number,
        pageReturn: String
    },
    data() {
        return {
            localPage: 1,
            localLimit: 0,
        };
    },
    methods: {
        MountPages() {
            return { name: this.pageReturn };
        },
    },
    watch: {
        localPage: function (newValue) {
            this.$emit("changePage", newValue);
        },
        currentPage(p) {
            this.localPage = p;
        },
    },
};
</script>
