<template>
<div class="site_limit">
  <div class="top">
    <span>
      <a href="/" class="linkHome">Home |</a>
      <h2>Veículos</h2>
    </span>
  </div>
  <div class="hideDesktop">
    <div class="filter">
        <b-button @click="showFilter()" variant="link danger">
          <inline-svg class="icon" :src="require(`@/assets/svg/btn_filter.svg`)"></inline-svg> 
          Filtrar 
          <inline-svg class="btn_arrow" v-if="filter" :src="require(`@/assets/svg/icon_arrow_up.svg`)" alt="+"></inline-svg>
          <inline-svg class="btn_arrow" v-else :src="require(`@/assets/svg/icon_arrow_down.svg`)" alt="+"></inline-svg>
          </b-button>
        <div class="sortby">
            <div class="clickable" @click="changeSort()">
              <span>{{ sortByTxt }} </span>
              <inline-svg class="btn_arrow" v-if="sort" :src="require(`@/assets/svg/icon_arrow_up.svg`)" alt="+"></inline-svg>
              <inline-svg class="btn_arrow" v-else :src="require(`@/assets/svg/icon_arrow_down.svg`)" alt="+"></inline-svg>
            </div>
        </div>
    </div>
  </div>
    <div class="side_menu hideDesktop" v-show="filter" v-if="filters != undefined">
      <div class="container" v-for="filter, i in filters" :key="i" :id="filter.id">
        <div class="badges" v-if="actualSearchsTypes.length > 0 && hasType(filter.id)">
          <div v-for="badge,x in actualSearchsTypes" :key="x">
            <div class="badge clickable" v-if="badge.type == filter.id" @click="searchDataRemove(badge.type, badge.search)">{{ badge.search }}<b-icon icon="x" /></div>
          </div>
        </div>
        <div class="title">{{ filter.title }}</div>
        <div class="elementS clickable" @click="searchDataAdd(filter.id, f.name), showFilter(false, 3000)" :id="`${filter.id}-${index}`" v-for="f,index in filter.items" :key="index" v-show="(index+1 <= max_menu_view || showMore[filter.id]) && !filterSelected[filter.id]">{{ f.name }} <span>({{ f.results }})</span></div>
        <div class="see_more clickable" @click="changeShowMore(filter.id, true)" v-show="filter.items.length > max_menu_view  && !showMore[filter.id] && !filterSelected[filter.id]">Mostrar mais <inline-svg :src="require(`@/assets/svg/icon_arrow_down.svg`)" alt="+"></inline-svg></div>
        <div class="see_more clickable" @click="changeShowMore(filter.id, false)" v-show="showMore[filter.id] && !filterSelected[filter.id]">Ocultar <inline-svg :src="require(`@/assets/svg/icon_arrow_up.svg`)" alt="-"></inline-svg></div>
      </div>
    </div>
  <div class="site_limit content_flex">
    <div class="side_menu hideMobile" v-if="filters != undefined">
      <div class="container">
        <div class="sortby">
            <div class="clickable" @click="changeSort()">
              <span>{{ sortByTxt }} </span>
              <inline-svg class="btn_arrow" v-if="sort" :src="require(`@/assets/svg/icon_arrow_up.svg`)" alt="+"></inline-svg>
              <inline-svg class="btn_arrow" v-else :src="require(`@/assets/svg/icon_arrow_down.svg`)" alt="+"></inline-svg>
            </div>
        </div>
      </div>
      <div class="container" v-for="filter, i in filters" :key="i" :id="filter.id">
        <div class="badges" v-if="actualSearchsTypes.length > 0 && hasType(filter.id)">
          <div v-for="badge,x in actualSearchsTypes" :key="x">
            <div class="badge clickable" v-if="badge.type == filter.id" @click="searchDataRemove(badge.type, badge.search)">{{ badge.search }}<b-icon icon="x" /></div>
          </div>
        </div>
        <div class="title">{{ filter.title }}</div>
        <div class="elementS clickable" @click="searchDataAdd(filter.id, f.name)" :id="`${filter.id}-${index}`" v-for="f,index in filter.items" :key="index" v-show="(index+1 <= max_menu_view || showMore[filter.id]) && !filterSelected[filter.id]">{{ f.name }} <span>({{ f.results }})</span></div>
        <div class="see_more clickable" @click="changeShowMore(filter.id, true)" v-show="filter.items.length > max_menu_view  && !showMore[filter.id] && !filterSelected[filter.id]">Mostrar mais <inline-svg :src="require(`@/assets/svg/icon_arrow_down.svg`)" alt="+"></inline-svg></div>
        <div class="see_more clickable" @click="changeShowMore(filter.id, false)" v-show="showMore[filter.id] && !filterSelected[filter.id]">Ocultar <inline-svg :src="require(`@/assets/svg/icon_arrow_up.svg`)" alt="-"></inline-svg></div>
      </div>
    </div>
    <div class="site_content">
      <ShowElement :modalId="pageReturn" :reloadCounter="reloadCounter" :dataUser="dataUser" :element="element" />

      <div class="element-docker bg-transparent flex-start" v-if="items.data.length > 0">
        <Element :id="`item-${index}`" v-for="item,index in items.data" :key="index" :item="item" @details="details" @interest="interest"></Element>
      </div>
      <div class="notFound" v-else v-show="!loading">Não foi encontrado nenhum resultado para sua busca.</div>

      <div class="spinner" v-show="loading"><b-spinner label="Carregando..."></b-spinner>Carregando...</div>

    </div>

    <div class="tools" v-if = "!desablePagination">
        <Search :data="data" @searchData="searchData"></Search>
        <Pagination :currentPage="currentPage" :totalPages="totalPages" :pageReturn="pageReturn" @changePage="changePage" />
    </div>  
  </div>
</div>
</template>

<script>
import MercadoLivre from "@/components/tools/mercadoLivre";
import Element from "@/components/Element";
import Pagination from "@/components/helpers/pagination";
import Search from "@/components/helpers/search";
import ShowElement from '@/components/ShowElement'

export default {
  name: "Vitrine",
    mixins: [ Pagination.mixin, Search.mixin, ShowElement.mixin ],
    components: { Element, Pagination, Search, ShowElement },
  props: {
    dataUser: Object,
  },
  mounted () {
    this.scroll()
  },
  created () {
    this.saveRouteParams()
  },
  data() {
    return {
      desablePagination: true,
      currentLimit: 50,
      autoCurrentPage: 1,
      items: {data : []},
      categories: [],
      filters: undefined,
      connection: undefined,
      category: undefined,
      pageReturn: "Vitrine",
      mlUser: null,
      loading: false,
      max_menu_view: 5,
      filter: false,
      actualSearchsTypes: [],
      filterSelected: {},
      sort: false,
      sortBy: 'price_asc',
      sortByTxt: 'Preço: Menor para Maior',
      showMore: {
        model: false,
        brand: false,
        vehicle_year: false,
        price: false,
        kilometers: false,
      },
    };
  },
  methods: {
    changeSort(){
      if(!this.loading){
        this.sort = !this.sort
        if(this.sort) { 
          this.sortBy = 'price_desc'
          this.sortByTxt = 'Preço: Maior para Menor'
        } else {
          this.sortBy = 'price_asc'
          this.sortByTxt = 'Preço: Menor para Maior'
        }
          this.currentPage = 1;
          this.autoCurrentPage = 1;
          this.totalPages = 1;
          this.loadData();
        }
        console.log(this.sortBy)
    },
    showFilter(x = undefined, time = 0) {
      if(x != undefined) {
        //set timeout and show filter
        setTimeout(() => { this.filter = x }, time)
      } else {
        setTimeout(() => { this.filter = !this.filter }, time)
      }
    },
    saveRouteParams() {
      let params = this.$route.params
      if(params != undefined) {
        this.searchData(params)
      }
    },
    /*
    changeMlUser(value) {
      this.connection.setNewUser(value);
      this.loadData();
      var data = {mlUser: value };
      localStorage.setItem('mlUser', JSON.stringify(data) );
    },
    verifyLocalStorage(){
      if(localStorage.getItem('mlUser')){
        var data = JSON.parse(localStorage.getItem('mlUser'));
        this.dataUser.ml_user = data.mlUser;
        this.changeMlUser(data.mlUser);
      }
    },
    */
    changeShowMore(type, value) {
      this.showMore[type] = value;
    },
    details(item) {
      this.$router.push('/veiculos/'+ item.id);
      //this.element = item.id;
      //this.reloadCounter++;
    },
    interest(item) {
      this.connection.interest(item.id);
    },
    async loadData(scrool = false) {
      if(scrool) { this.autoCurrentPage++; }
      if(this.autoCurrentPage > this.totalPages) { return false; }
      this.loading = true;
      if(this.connection != undefined){
        await this.connection.products(
        this.currentLimit,
        (scrool) ? this.autoCurrentPage : this.currentPage,
        this.query,
        this.category,
        this.sortBy
      ).then((response) => {
          this.filters = this.connection.getFilters();
          if(scrool) {
            this.items.data = this.items.data.concat(response.data);
          } else {
            this.items = response;
            this.totalPages = this.items.pagination.pages;
          }
      })
      this.loading = false;
      }
    },
    async loadCategory() {
      if(this.connection != undefined){
        await this.connection.categories().then((response) => {
          this.categories = response;
      })       
      }
    },
    async searchData(item){
      if(!this.loading){
        this.currentPage = 1;
        this.autoCurrentPage = 1;
        this.totalPages = 1;
        this.query = item.search
        this.data.search = item.search
        await this.loadData();
      }
    },
    hasType(id){
      return this.actualSearchsTypes.find(f => f.type == id);
    },
    sortActualSearchsTypes(list){
        let array = Object.values(list);
        array.sort((a, b) => {
            if (a.search < b.search) return -1;
            if (a.search > b.search) return 1;
            return 0;
        });
        let object = Object.fromEntries(array.map((item, index) => [index, item]));
        return Object.values(object);
    },
    searchDataAdd(type, search){
      if(!this.loading){
        if(!(this.actualSearchsTypes.some(item => item.type == type))){
            this.actualSearchsTypes.push({type: type, search: search});
            this.actualSearchsTypes = this.sortActualSearchsTypes(this.actualSearchsTypes);
            var x = (this.query != undefined) ? this.query + ',' : "";
            this.searchData({ 'search' : x  + search});
            this.filterSelected[type] = true;
        } else {
          var actual = this.actualSearchsTypes.find(item => item.type == type);
          this.searchDataRemove(type, actual.search, true);
          this.searchDataAdd(type, search);
        }
      }
    },
    searchDataRemove(type, search, skip = false){
      if(!this.loading){
        if(this.actualSearchsTypes.some(item => item.type == type)){
          this.actualSearchsTypes = this.actualSearchsTypes.filter(item => item.type !== type);
          this.actualSearchsTypes = this.sortActualSearchsTypes(this.actualSearchsTypes);
          var x = (this.query != undefined) ? this.query.replace(search + ',', '') : "";
          x = x.replace(',' + search, '');
          x = x.replace(search, '');
          x = x.replace(/^,/, '');
          x = x.replace(/,$/, '');
          this.query = x;
          
          if(!skip){ 
            this.searchData({ 'search' : this.query }); 
            this.filterSelected[type] = false;
            }
        } 
      }
    },
    changePage(p) {
        this.currentPage = p;
        this.loadData();
    },
    isScrolledIntoView (el) {
      let rect = el.getBoundingClientRect()
      let elemTop = rect.top
      let elemBottom = rect.bottom

      let isVisible = elemTop < window.innerHeight && elemBottom >= 0
      return isVisible
    },
    scroll () {
      window.onscroll = () => {
        var lastItem = this.autoCurrentPage*45;
        let scrolledTo = document.querySelector(`#item-${lastItem}`);

        if (scrolledTo && this.isScrolledIntoView(scrolledTo)) {
          this.loadData(true);
        }
      }
    }
  },
  watch: {
    dataUser: {
      async handler(){
        this.connection = await new MercadoLivre(this.dataUser.ml_user, this.dataUser.ml_default_category);
        await this.loadData();
      }
    }
  },
};
</script>

<style lang="scss">

.hideDesktop {
  display: flex;
  @media (min-width: 920px) { display: none !important; }
}

.hideMobile {
  display: flex;
  @media (max-width: 919px) { display: none !important; }
}

.sortby {
  font-size: 14px;
  font-weight: 400;
  color: #000;
  span {
    margin-right: 5px;
  }
}

.filter {
  display: flex;
    padding: 6px 10px;
    background-color: #ffffff;
    border-radius: 6px;
    margin-bottom: 12px;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    width: 100%;
    align-items: center;

    button.danger {
      color: var(--s-badge);
      text-decoration: none;
      padding: 0px;
    }

    .btn_arrow {
      width: 12px;
      height: 12px;
    }
    
    @media (max-width: 400px) { 
      padding: 6px;
      flex-direction: column;
      justify-content: space-between;
      align-content: center;
      gap: 10px;
     }

    .badges {
      display: flex;
      flex-wrap: wrap;
      justify-content: flex-end;
      align-self: center;
      align-content: center;
      margin-right: 5px;
      gap: 6px;

      @media (max-width: 400px) { 
        margin: 0 0 16px 0;
        justify-content: flex-start;
      }
    }
}

  .side_menu {
    width: 100%;
    padding: 0px;
    display: flex;
    flex-direction: column;
    gap: 12px;
    margin-bottom: 24px;
  }

.notFound {
    text-align: center;
    color: var(--s-danger);
}

.element-docker {
  justify-content: flex-start !important;
  padding: 0 !important;

  @media (max-width: 919px) { justify-content: center !important; }
  
}

.badges {
    display: flex;
    margin: -5px;
    flex-wrap: wrap;
    justify-content: flex-end;
    align-self: flex-end;
    height: 0px;

    .badge {
        padding: 5px 10px;
        background-color: var(--s-badge);
        color: #ffffff;
        font-weight: 200;
        display: flex;

        svg {
          width: 14px;
          height: 14px;
          fill: #ffffff;
        }
    }
}

.top .badges {
    display: flex;
    gap: 5px;
    align-content: flex-end;
    flex-direction: row;
    justify-content: flex-end;
    margin-right: 0px;
}

.select {
    width: 100%;
    max-width: 450px;
    margin: 10px auto;
    align-self: center;
}

.content_flex{
  display: flex;
  gap:10px;

  .side_menu{
    width: 25%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    padding: 0px;
    gap: 12px;
    margin-bottom: 24px;
  }

  .site_content {
    width: 100%;
    margin-bottom: 24px;
  }
}
</style>