<template>
  <div v-if="l_element != undefined">
    <b-modal modal-class="modaldetails" :id="modalId" :ref="modalId" hide-footer :title="l_element.title" >
      <div class="grid">
          <div class="col1">
            <b-carousel id="carousel" ref="carousel" class="carousel" :controls="true" :indicators="true" :interval="4000">
              <b-carousel-slide v-for="(image, index) in l_element.images" :key="index" :img-src="image.image">
              </b-carousel-slide>
            </b-carousel>
          </div>
          <div class="col2">
            <div class="id">{{l_element.id}}</div>
            <div class="title">{{l_element.title}}</div>
            <div class="price">{{l_element.price | showPrice}}</div>
            <div class="condition">{{l_element.condition | showCondition }}</div>
            <div class="location">{{l_element.location.city}}/{{l_element.location.state}}</div>
            <div class="characteristics">Características principais</div>
            <div class="attributes">
              <div v-for="attribute, i in l_element.attributes" :key="`at${i}`" class="items" :class="(i%2 !=0) ? 'odd' : ''">
                  <span class="name">{{ attribute.name }}</span>
                  <span v-if="attribute.value != 'Sim'">:</span>
                  <span v-if="attribute.value != 'Sim'" class="value">{{ attribute.value }}</span>
              </div>
            </div>
            <b-tabs content-class="tabs">
              <b-tab title="WhatsApp e Telefones" active><ContactPhones :dataUser="dataUser" /></b-tab>
              <b-tab title="Enviar uma mensagem"><InterestForm :dataUser="dataUser" :product="l_element" /></b-tab>
            </b-tabs>
          </div>
      </div>
    </b-modal>
  </div>
</template>

<script>

var mixin = {
    data() {
        return {
            element: undefined,
            reloadCounter: 0,
        }
    },
}

import MercadoLivre from "@/components/tools/mercadoLivre";
import showPrice from '@/components/helpers/showPrice'
import showCondition from '@/components/helpers/showCondition'
import InterestForm from '@/components/interestForm'
import ContactPhones from '@/components/contactPhones'

export default {
  mixin,
  mixins: [ showPrice, showCondition ],
  components: { InterestForm, ContactPhones },
  name: "ShoewElement",
  async mounted() {
    this.connection = await new MercadoLivre();
  },
  props: {
    modalId: String,
    element: String,
    reloadCounter: Number,
    dataUser: Object,
  },
  data() {
    return {
      connection: undefined,
      l_element: undefined,
    };
  },
  methods: {
    async loadData() {
      if (this.element != undefined) {
          this.l_element = await this.connection.product(this.element);
      } else {
          this.closeThis();
      }
    },
    closeThis() {
      this.$root.$emit("bv::toggle::modal", this.modalId);
    },
  },
  watch: {
    async reloadCounter() {
        await this.loadData();
        this.$root.$emit("bv::toggle::modal", this.modalId);
    },
  },
};
</script>
