<template>
    <div class="element" v-if="item != undefined">
        <div class="clickable photo" @click="details()">
        <b-carousel class="carousel_home" v-if="item.images != undefined"
            :interval="0" controls indicators
            @sliding-start="onSlideStart"
            @sliding-end="onSlideEnd">
            <b-carousel-slide v-for="x, i of item.images" :key="i" :img-src="x.image">
            <template #img><img class="carousel_img" :src="x.image" alt="image"></template>
            </b-carousel-slide>
        </b-carousel>
        <div class="thumbnail" v-else><img :src="item.thumbnail"></div>
        </div>
        <div class="clickable gap" @click="details()">
            <div class="title">{{ item.title }}</div>
            <div class="attributes">
                <div class="year">
                    <inline-svg :src="require(`@/assets/svg/detail_year.svg`)" alt="ano"></inline-svg>
                    {{ item.year }}
                </div>
                <div class="km">
                    <inline-svg :src="require(`@/assets/svg/detail_kilometer.svg`)" alt="ano"></inline-svg>
                    {{ item.km | showNumberInt }}
                </div>
            </div>
            <div class="price">{{ item.price | showPriceInt }}</div>
            <b-button-group class="actions">
                <button class="btn details" @click="details()">Ver Detalhes</button>
            </b-button-group>
        </div>
    </div>
</template>

<script>
import showPrice from '@/components/helpers/showPrice'
import showNumber from '@/components/helpers/showNumber'
import limitPhraseCaracters from '@/components/helpers/limitPhraseCaracters'

export default {
    name: "Element",
    mixins: [ showPrice, showNumber, limitPhraseCaracters ],
    props: {
        item: Object,
    },
    data() {
        return {
            slide: 0,
            sliding: null,
        };
    },
    methods: {
        onSlideStart() {
        this.sliding = true
        },
        onSlideEnd() {
        this.sliding = false
        },
        details() {
            this.$emit('details', this.item);
        },
        interest() {
            this.$emit('interest', this.item);
        }
    },
};
</script>