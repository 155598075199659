var mixin = {
    filters:{
        limitPhraseCaracters(value) {
            var l = 28
            if (value.length > l) {
                return value.substring(0, l) + '...';
            }
            return value;
        },
    }
}

export default mixin;
